module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Puma FastPay","short_name":"Puma FastPay","start_url":"/","icon":"static/favicon/favicon-32x32.png","icons":[{"src":"static/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"static/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"static/favicon/maskable_icon.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"background_color":"#007649","theme_color":"#007649","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a411db671cc82357d887b3c61b38ddc4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-79652807-3"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
